<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/machineList">设备管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/machineList">设备列表 </a></slot></span
          >
          <span slot="l"><slot>> 创建设备 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="machineForm"
            :rules="rules"
            ref="machineForm"
            label-width="150px"
            class="machineForm"
          >
            <el-form-item label="设备名：" prop="device_name">
              <el-input
                v-model="machineForm.device_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备备注名：">
              <el-input
                v-model="machineForm.nickname"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属客户公司：">
              <el-select
                v-model="machineForm.companyid"
                placeholder="请选择"
                @change="companySort"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属油田：">
              <el-select
                v-model="machineForm.oilfieldid"
                placeholder="请选择指定油田"
              >
                <el-option
                  v-for="item in oilfieldOpitons"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="冲程：">
              <el-input
                v-model.number="machineForm.device_stroke"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="泵径：">
              <el-input
                v-model.number="machineForm.device_diameter"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="reset" @click="resetForm('machineForm')"
                >重置</el-button
              >
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('machineForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import MachineJS from 'network/machine/machine.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 3,
      machineForm: {
        device_name: '',
        nickname: '',
        companyid: '',
        oilfieldid: '',
        device_stroke: null,//冲程
        device_diameter:null,//泵径
      },
      rules: {
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        device_name: [
          { required: true, message: '请输入设备名', trigger: 'blur' },
          {
            pattern: /^[0-9A-Za-z]{1,20}$/,
            message: '设备名不能包括汉字和标点符号且长度为1-20位',
            trigger: 'blur'
          }
        ]
      },
      companyOptions: [],
      oilfieldOpitons: []
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 创建新设备
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.deviceCreate()
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    companySort(v) {
      this.getOilfieldList(v)
    },
    /**
     * 网络请求
     */
    deviceCreate() {
      this.machineForm.session_id = this.$store.state.loginInfo.session_id
      MachineJS.deviceCreate(this.machineForm).then((res) => {
        if (res.code == 0) {
          const h = this.$createElement
          this.$msgbox({
            title: '消息',
            message: h('div', null, [
              h('p', null, 'productkey: ' + res.productkey),
              h('p', null, '设备名: ' + res.device_name),
              h('p', null, '设备密钥: ' + res.device_secret)
            ]),
            beforeClose: (action, instance, done) => {
              this.resetForm('machineForm')
              this.$router.replace('/machineList')
              done()
            }
          }).then((action) => {
            if (action) {
              this.resetForm('machineForm')
              this.$router.replace('/machineList')
            }
          })
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取公司列表
    getCompanyList() {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        count: 99,
        page: 0
      }
      MachineJS.companyList(param).then((res) => {
        if (res.code == 0) {
          const arr = []
          for (const i of res.company_list) {
            const obj = { value: '', label: '' }
            obj.value = i.companyid
            obj.label = i.name
            arr.push(obj)
          }
          this.companyOptions = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取油田列表
    getOilfieldList(v) {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        companyid: v,
        count: 99,
        page: 0
      }
      MachineJS.oilfieldList(param).then((res) => {
        if (res.code == 0) {
          this.oilfieldOpitons = []
          this.machineForm.oilfieldid = ''
          const arr = []
          for (const i of res.oilfield_list) {
            const obj = { value: '', label: '' }
            obj.value = i.oilfieldid
            obj.label = i.name
            arr.push(obj)
          }
          this.oilfieldOpitons = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  },
  mounted() {
    this.getCompanyList()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .machineForm {
      padding: 85px 0 0 76px;
      width: auto;
      height: auto;
      .tips {
        margin-left: 17px;
        width: auto;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(1, 158, 229, 0.88);
      }
      .reset {
        margin-left: 90px;
        border-radius: 0;
      }
      .submit {
        margin-left: 20px;
        border-radius: 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 28px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 42px;
      }
    }
  }
}
</style>
