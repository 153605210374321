import { request } from '../request';

// 创建设备
const deviceCreate = function (params) {
  return request({
    url: 'api/device/create',
    method: 'post',
    data: params
  })
};
// 设备列表
const deviceList = function (params) {
  return request({
    url: 'api/device/list',
    method: 'post',
    data: params
  })
};
// 设备基本信息
const deviceGet = function (params) {
  return request({
    url: 'api/device/get',
    method: 'post',
    data: params
  })
};
// 修改设备
const deviceUpdate = function (params) {
  return request({
    url: 'api/device/update',
    method: 'post',
    data: params
  })
};
// 公司列表
const companyList = function (params) {
  return request({
    url: 'api/company/list',
    method: 'post',
    data: params
  })
};
// 用户个人基本信息
const userGet = function (params) {
  return request({
    url: 'api/user/get',
    method: 'post',
    data: params
  })
};
// 油田列表
const oilfieldList = function (params) {
  return request({
    url: 'api/oilfield/list',
    method: 'post',
    data: params
  })
};
// 客户公司关联设备
const cpmpanyAddDevice = function (params) {
  return request({
    url: 'api/company/add_device',
    method: 'post',
    data: params
  })
};
// 油田添加设备
const oilfieldAddDevice = function (params) {
  return request({
    url: 'api/oilfield/add_device',
    method: 'post',
    data: params
  })
}
export default {
  deviceCreate,
  deviceList,
  deviceGet,
  deviceUpdate,
  companyList,
  userGet,
  oilfieldList,
  cpmpanyAddDevice,
  oilfieldAddDevice
}
